export const statusOption = [{
    value: '1',
    text: '启用中'
}, {
    value: '2',
    text: '停用中'
}, {
    value: '3',
    text: '待启用'
}];

export const initForm = {
    external_id: '',
    scene_pack_name: '',
    scene_pack_status: '',
    publish_time: '',
    discount: ''
};

export const column = [{
    label: '序号',
    prop: 'list_order',
    minWidth: 100
}, {
    label: '场景包ID',
    prop: 'external_id',
    minWidth: 100
}, {
    label: '场景包名称',
    prop: 'scene_pack_name',
    showOverflowTooltip: true,
    minWidth: 140
}, {
    label: '场景包主图',
    prop: 'banner',
    minWidth: 100
}, {
    label: '场景包状态',
    prop: 'status',
    minWidth: 140
}, {
    label: '启用时间',
    prop: 'publish_time',
    minWidth: 140
// }, {
//     label: '省下折扣',
//     prop: 'discount',
//     minWidth: 100
}, {
    label: '操作',
    prop: 'operation',
    fixed: 'right',
    width: 180
}];
/**
 * 表格操作按钮
 * @param {number} status 状态
 * @returns {Array}
*/
export const operationBtn = (status) => [{
    type: 'edit',
    text: '编辑',
    resource: 'scene_pack_edit'
}, {
    type: 'able',
    text: '启用',
    status: ['2'],
    resource: 'scene_pack_change_status_on'
}, {
    type: 'disable',
    text: '停用',
    status: ['1', '3'],
    resource: 'scene_pack_change_status_off'
}].filter((item) => !item.status || item.status.indexOf(status) > -1);
