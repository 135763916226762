<template>
    <div v-if="hasResource('scene_pack_management')" class="aops-scene">
        <div class="search-form">
            <el-button
                v-if="hasResource('scene_pack_add')"
                class="black-btn btn-l"
                @click="handleAdd"
            >新增</el-button>
            <SearchForm :form="form" @onSearch="handleSearch" @onReset="handleReset" />
        </div>
        <el-table
            v-loading="tableLoading"
            :data="listData"
            style="width: 100%;"
            header-cell-class-name="table-head"
            cell-class-name="table-cell"
            class="data-table"
        >
            <el-table-column
                v-for="col in column"
                :key="col.prop"
                :label="col.label"
                :prop="col.prop"
                :min-width="col.minWidth"
                :width="col.width"
                :show-overflow-tooltip="col.showOverflowTooltip"
                :fixed="col.fixed"
            >
                <template slot-scope="{row}">
                    <!-- <div v-if="col.prop === 'number'">{{$index + 1}}</div> -->
                    <div v-if="col.prop === 'banner'">
                        <img class="aop-scene-img" :src="row.banner.image_url" alt=""  />
                    </div>
                    <!-- 状态 -->
                    <span v-else-if="col.prop === 'status'">
                        {{row.status | statusFilter}}
                        <div>({{`${row.asset_status}` === '1' ? '正常' : '部分资产下架'}})</div>
                    </span>
                    <span v-else-if="col.prop === 'publish_time'">
                        {{row[col.prop] | dateFilter}}
                    </span>
                    <!-- 折扣 -->
                    <span v-else-if="col.prop === 'discount'">
                        {{row.discount ? `-${row.discount}%` : '--'}}
                    </span>
                    <div v-else-if="col.prop === 'operation'">
                        <template v-for="item in getOperaBtnData(row.status)">
                            <el-button
                                v-if="!item.resource || hasResource(item.resource)"
                                :key="item.type"
                                size="mini"
                                type="text"
                                @click="handleOperation(item, row)"
                            >
                                {{item.text}}
                            </el-button>
                        </template>
                    </div>
                    <span v-else>{{row[col.prop] || '--'}}</span>
                </template>
            </el-table-column>
        </el-table>
        <div class="ta-r mg-t-18">
            <el-pagination
                :page-sizes="[10,20,50]"
                :page-size="pagesize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                :current-page.sync="currentPage"
                @size-change="handleSizeChange"
                @current-change="handleSearch"
            ></el-pagination>
        </div>
        <statusDialog
            :dialog-visible.sync="statusDialogVisible"
            :scene="curScene"
            @onHandleQuery="handleSearch"
        />
    </div>
</template>

<script>
import { formatDate } from 'ecoplants-lib';
import Api from '@/api/scene';
import SearchForm from './search/search.vue';
import statusDialog from './statusDialog/statusDialog.vue';
import { initForm, column, statusOption, operationBtn } from './data';

export default {
    components: { SearchForm, statusDialog },
    filters: {
        /**
         * 表格状态格式化
         * @param {number} val 状态
         * @returns {string | number}
        */
        statusFilter(val) {
            const child = statusOption.find((item) => item.value === `${val}`);
            return child ? child.text : val;
        },
        /**
         * 时间格式化
         * @param {number} val 时间戳
         * @returns {string}
         */
        dateFilter(val) {
            return val ? formatDate(val * 1000, 'yyyy-MM-dd hh:mm:ss') : '--';
        }
    },
    /**
     * data
     * @returns {*}
     */
    data() {
        return {
            form: { ...initForm }, // 查询表单
            column, // 表格的列
            listData: [],
            tableLoading: '',
            pagesize: 10,
            total: 0,
            currentPage: 1,
            curScene: {},
            statusDialogVisible: false // 停用/启用弹窗

        };
    },
    /**
     * mounted
     */
    mounted() {
        this.getList();
    },
    methods: {
        /**
         * 获取场景包表格数据
         */
        getList() {
            this.tableLoading = true;
            const data = {
                limit: this.pagesize,
                page: this.currentPage,
                filter: {
                    ...this.form,
                    scene_pack_status: this.form.scene_pack_status ? [this.form.scene_pack_status] : '', // 传参是数组
                    publish_time: {
                        from: this.form.publish_time && this.form.publish_time[0] / 1000,
                        to: this.form.publish_time && this.form.publish_time[1] / 1000
                    },
                    discount: {
                        value: this.form.discount
                    }
                }
            };
            Api.sceneList(data).then((res) => {
                if (res.data.code === 10200) {
                    this.listData = res.data.data.items;
                    this.total = res.data.data.total;
                } else {
                    this.$$error(res.data.message);
                }
                this.tableLoading = false;
            }).catch(() => {
                this.tableLoading = false;
            });
        },
        /**
         * 点击新增/编辑按钮
         * @param {*} query 路由参数
         */
        handleAdd(query = {}) {
            this.$router.push({
                path: '/operations/add-scene',
                ...query
            });
        },
        /**
         * 点击重置
         */
        handleReset() {
            this.form = { ...initForm };
        },
        /**
         * 分页
         * @param {number} val 一页多少条
        */
        handleSizeChange(val) {
            this.pagesize = val;
            this.getList();
        },
        /**
         * 查询
         * @param {number} val 页码
        */
        handleSearch(val) {
            this.currentPage = val;
            this.getList();
        },
        /**
         * 处理操作按钮
         * @param {Object} item 操作按钮
         * @param {Object} row 操作的积分方案
        */
        handleOperation(item, row) {
            this.curScene = { ...row };
            switch (item.type) {
                case 'edit':
                    this.handleAdd({
                        query: {
                            id: row.external_id
                        }
                    });
                    break;
                case 'disable':
                    this.handleDiabled(row);
                    break;
                case 'able':
                    this.statusDialogVisible = true;
                    break;
                default:
                    break;
            }
        },
        /**
         * 停用
         * @param {*} row row
        */
        handleDiabled(row) {
            const activeBanners = (row.banners || []).filter((item) => `${item.status}` !== '2');
            if (activeBanners.length > 0) {
                // 点击停用按钮时，如果关联了启用中和待启用中的banner
                const names = activeBanners.map((ele) => ele.banner_name);
                this.$$warning(`该场景包关联了 ${names.join(',')} banner，需先停用对应banner`);
                return;
            }
            this.statusDialogVisible = true;
        },
        /**
         * 获取表格操作按钮
         * @param {number} status 状态
         * @returns {Array}
        */
        getOperaBtnData(status) {
            return operationBtn(status);
        }
    }
};
</script>
<style lang="less">
@import "./index.less";
</style>
